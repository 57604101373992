.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  position: relative;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background: #dbdbdb;
  border-radius: 5px;
  padding-left: 7px;
  &:hover {
    opacity: 0.75;
  }
}

.download_icon {
  width: 29px;
  height: 32px;
}

.play_icon {
  width: 32px;
  height: 32px;
}

.text_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-duration: 150ms;
}

.text {
  font-size: 16.5px;
  line-height: 19px;
  font-weight: 700;
  @media (max-width: 480px) {
    font-size: 15.5px;
    line-height: 18px;
  }
}

.price_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 166px;
  white-space: nowrap;
  height: 100%;
  border-left: 1px solid #303030;
  transition-duration: 150ms;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 22.5px;
  line-height: 26px;
  font-weight: 700;
  color: #c62628;
  padding-right: 1px;
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 23px;
    min-width: 135px;
  }
}

.old_price_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.old_price {
  padding-right: 5px;
  font-size: 19.5px;
  line-height: 22.5px;
  font-weight: 700;
  color: #303030;
  @media (max-width: 480px) {
    font-size: 17.5px;
    line-height: 20px;
  }
}
