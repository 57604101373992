.container {
  display: flex;
  flex-wrap: wrap;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 15.5px;
  line-height: 15px;
  margin-right: 5px;
}

.pill {
  display: block;
  white-space: nowrap;
  margin-right: 8px;
  margin-bottom: 5px;
  &:hover {
    opacity: 0.75;
  }
}

.pill_text {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 34.5px;
  width: 100%;
  height: 100%;
  background: rgba(217, 217, 217, 0.6);
  font-weight: 400;
  font-size: 15.5px;
  line-height: 14px;
  padding: 5px 15px;
}
