.pagination-component {
  text-align: center;
  margin-top: 50px;
  .pagination {
    li {
      display: inline-block;
      font-weight: 600;
      padding: 5px 10px;
      font-size: 20px;
      color: $mainRed;
    }
    .active {
      color: rgba(0, 0, 0, 0.8);
    }
    i {
      color: $mainRed;
    }
  }
}

// .pagination-component {
//   text-align: center;
//   margin-top: 50px;
//   .pagination {
//     li {
//       display: inline-block;
//       font-weight: 600;
//       background-color: rgba(0, 0, 0, 0.1);
//       padding: 5px 10px;
//       font-size: 20px;
//       color: rgba(0, 0, 0, 0.8);
//       margin-right: 4px;
//       cursor: pointer;
//       a {
//         display: block;
//       }
//     }
//     .active {
//       color: $mainRed;
//     }
//     i {
//       color: $mainRed;
//     }
//   }
// }
