@import 'src/assets/styles/partials/variables';

.noOfferCard {
  min-width: 200px;
  max-width: 200px;
  min-height: 256px;
  max-height: 256px;
  padding: 16px 12px 12px;
  color: white;
  font-family: SourceSansPro;
  border-radius: 6px;

  @media screen and (max-width: 650px) {
    min-width: 160px;
    max-width: 160px;
    min-height: 220px;
    max-height: 220px;
  }

  @media screen and (min-width: 341px) and (max-width: 400px) {
    min-width: 150px;
    max-width: 150px;
    min-height: 220px;
    max-height: 220px;
  }



  @media screen and (max-width: 340px) {
    min-width: 130px;
    max-width: 130px;
    min-height: 220px;
    max-height: 220px;
  }

}

.noOfferVipCard {
  background-color: $yellow-bg;
}

.noOfferBonusCard {
  background-color: $purple-bg;
}

.noOfferCardIcon {
  max-width: 64px;
  background-color: #ffffff;
  border-radius: 100%;
  padding: 15px;

  svg {
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 400px) {
    padding: 10px;

    svg {
      width: 25px;
      height: 25px;
    }

  }
}

.noOfferBonusCardIcon {
  color: $purple-bg;
}

.noOfferVipCardIcon {
  color: $yellow-bg;
}

.noOfferVipCardPrice {
  font: normal normal bold 30px/125% "SourceSansPro";

  @media screen and (max-width: 500px) {
    font-size: 26px;
  }

  @media screen and (max-width: 400px) {
    font-size: 20px;
  }

}

.noOfferVipCardText {
  color: #ffffff;
  opacity: 0.8;
  text-align: center;
  font: normal normal normal 18px/125% "SourceSansPro";

  @media screen and (max-width: 500px) {
    font-size: 15px;
  }

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }

}

.noOfferVipCardBottomText {
  color: #ffffff;
  text-align: center;
  font: normal normal bold 15px/125% "SourceSansPro";

  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
}