.newIcon {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: -13px;
  right: -13px;
  width: 70px;


  @media (max-width: 650px) {
    width: 40px;
    top: -8px;
    right: -8px;
  }

  @media (max-width: 1366px) {
    width: 60px;
    top: -8px;
    right: -8px;
  }
}



.reducedIcon {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: -13px;
  left: -13px;
  width: 70px;
  transform: rotate(270deg);

  @media (max-width: 650px) {
    width: 40px;
    top: -8px;
    left: -8px;
  }

  @media (max-width: 1366px) {
    width: 60px;
    top: -8px;
    left: -8px;
  }

}




.audioVipTop100Section {
  position: absolute;
  top: 60%;
  transform: translateY(-60%);
  right: -13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 145px;
  gap: 5px;
  z-index: 10;

  @media (max-width: 650px) {
    top: 80%;
    transform: translateY(-80%);
    right: -8px;
  }

  @media (max-width: 1366px) {
    right: -8px;
  }

}

.flag {
  width: 45px;
  height: 45px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  @media (max-width: 650px) {
    width: 40px;
    height: 40px;
  }

}