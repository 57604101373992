.carousel-scene {
  border-radius: 8px;

  &:hover {
    .video-js {
      z-index: 0;
    }
  }

  .german-audio {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
  }

  .video-js {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    object-fit: fill !important;
    z-index: -1;
    height: 100% !important;
    border-radius: 8px;
    &:not(video) {
      padding-top: 75% !important;
    }
  }
  video {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    object-fit: cover !important;
    z-index: -1;
  }

  /*&__play-button {
    width: 6rem !important;
    height: 6rem !important;
    margin-left: calc(50% - 3rem);
    opacity: 0.5;

  }*/
}

.scene4-3 {
  padding-top: 73%;
  position: relative;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 8px;


  @media screen and (max-width: 650px) {
    margin-bottom: 10px;
  }




  .hover-effect-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    border-radius: 8px;
    z-index: 10;

    .hover-effect {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
    }


    .play-icon {
      flex: 2;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;

      img {
        width: 50px !important;
        height: 50px !important;
      }

    }

    .play-icon-container {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity .3s ease-out;
      z-index: 100;

      img {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
      }
    }

    .scene-item-title {
      margin: 0;
      font-size: 18px;
      line-height: 125%;
      text-align: center;
      color: #ffffff !important;
      letter-spacing: 0.4px;
      text-shadow: 1px 1px 1px #333333;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      overflow-x: hidden;
      max-width: 100%;
      padding: 8px 4px;
      text-overflow: ellipsis;
      z-index: 11;
    }

  }



  &:hover {
    .video-js {
      z-index: 0;
    }
  }
  .german-audio {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    filter: brightness(1.2);
    border-radius: 8px;
  }

  .video-js {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    object-fit: cover !important;
    z-index: -1;
  }
  video {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    object-fit: cover !important;
    z-index: -1;
  }

  &__play-button {
    width: 6rem !important;
    height: 6rem !important;
    margin-left: calc(50% - 3rem);
    opacity: 0.5;
  }
}
.scene4-3-cover {
  position: relative;
  width: 100%;
  .german-audio {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  video {
    object-fit: cover;
  }
}
.scenes-list-container {
  grid-auto-rows: 1fr;
}
