.ripple_button {
  border: none;
  margin: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.ripple_button:focus {
  outline: 2px dotted;
}

.ripple_button > .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  display: block;
  content: '';
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.ripple_button > .content {
  position: relative;
  z-index: 2;
}
