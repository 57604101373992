.container {
  background-color: #8695a9;
  width: 100%;
  border-radius: 7px;
  margin-bottom: 1.25rem;
  max-width: 660px;
  height: 210px;
  @media (max-width: 480px) {
    min-height: 274px;
  }
}
.wrapper {
  padding-top: 0.5rem;
}

.banner_container {
  position: relative;
  height: 102px;

  @media (max-width: 480px) {
    height: 123px;
  }
}

.banner_wrapper {
  position: absolute;
  top: 0;
  margin-left: 2.5rem;
  margin-top: 1rem;
  @media (max-width: 480px) {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }
}

.bgStudios_img {
  width: 100%;
  height: 100%;
}

.ul {
  list-style-type: disc;
}

.li {
  margin-left: 1.5rem;
  font-size: 14.5px;
  line-height: 16.5px;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.price_container {
  height: 44px;
  width: 100%;
}

.price_wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.price_wrapper_left {
  display: flex;
  align-items: center;
  height: 100%;
  width: 70%;
  border-right: 1px solid #ffffff;
  padding-right: 30px;
  @media (max-width: 480px) {
    justify-content: center;
    width: 50%;
  }
}

.money_icon {
  width: 29px;
  height: 29px;
  margin-right: 10px;
  margin-left: 16px;
}

.text {
  width: 100%;
  white-space: nowrap;
  color: #ffffff;
  text-align: center;
  @media (max-width: 480px) {
    text-align: unset;
    width: 50%;
  }
}

.money_text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  font-size: 22px;
  line-height: 25px;
  white-space: nowrap;
  color: #ffffff;
  @media (max-width: 480px) {
    width: 50%;
  }
}

.per_month {
  font-size: 13px;
  line-height: 15px;
  margin-left: 10px;
}

.proceed_button {
  width: 230px;
  height: 45px;
  background: #2fb782;
  border-radius: 44px;
  font-size: 15px;
  list-style: 20px;
  color: #ffffff;
  margin-top: 6px;
}

.button_container {
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 7px;
  position: relative;
  background: #000000;
  @media (max-width: 480px) {
    height: 99px;
  }
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: absolute;
  padding-right: 32px;
  @media (max-width: 480px) {
    position: unset;
    padding-right: unset;
    justify-content: center;
    background: #000000;
  }
}

.button_banner {
  margin-bottom: auto;
  border-radius: 7px;
}
