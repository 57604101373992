#movie-page {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  .movie-title {
    font-size: 28px;
  }
}
.trailer-container {
  width: 90vw;
  max-width: 1200px;
}
.rating-stars-container-active {
  transition: all 0.3s;
  i {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    transition: transform 200ms ease-in-out;
    cursor: pointer;
    font-size: 25px;
    width: 30px;
  }
  i:hover {
    transform: scale(1.4);
  }
}
.rating-stars-container-inactive {
  i {
    font-size: 25px;
    width: 30px;
  }
  animation: bounce 500ms ease alternate;
  -webkit-animation: bounce 500ms ease alternate;

  pointer-events: none;
}
@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}
