.scene_title_container {
  cursor: pointer;
  width: 98.5px;
  height: 33.5px;
  background: #e0e5ec;
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin-top: 9px;
  margin-right: 15px;
  &:hover {
    opacity: 0.75;
  }
}

.title {
  font-weight: 400;
  font-size: 15.5px;
  line-height: 15px;
}
