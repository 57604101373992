// Font variables
$siteBaseFont: 'SourceSansPro';
$siteSectionFont: 'WorkSans';

// BUM colors
$mainBg: #1c1c1c;
$headerBg: #282828;
$lightHeader: #2d2d2d;
$mainRed: #c8241e;
$newRead: #DD0000;
$mainYellow: #f2ba55;
$mainYellow1: #E5AC06;
$mainPurple: #8C36AB;
$mainGreen: #4ec867;
$mainGreenDark: #10b981;
$mainGreenDarken: #46b45d;
$mobHeaderBg: #343434;
$lightDark: #484848;
$mainGray1: #f0f0f0;
$mainGray2: #d2d2d2;
$mainGray3: #1e1e1e0d;
$mainGray4: #1e1e1e;
$mainBlueGray: #8695A9;
$mainBlue: #1a0199;

$yellow-bg: #EEAE3D;
$purple-bg: #A345C4;
$green-bg: #36B683;
$light-blue-gray-bg: #E2E8F0;
$blue-gray-bg: #8695A9;