@import 'src/assets/styles/partials/variables';

.bonusFormContainer {
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 650px) {
    box-shadow: none;
  }
}

.bonusHeader {
  background-color: $purple-bg;
  font-family: SourceSansPro;
  color:#ffffff;
  padding: 12px 60px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media screen and (max-width: 650px) {
    padding: 12px 25px 20px;
  }
}

.bonusHeaderIcon {
  background-color: #ffffff;
  border-radius: 100%;
  color: $purple-bg;
  padding: 20px;

  svg {
    width: 35px;
    height: 35px;
  }

  @media screen and (max-width: 650px) {
    padding: 15px;

    svg {
      width: 30px;
      height: 30px;
    }
  }


}

.bonusPackagesMethodContainer {
  gap: 45px;
  height: 365px;

  @media screen and (max-width: 650px) {
    gap: 10px;
  }
}


.bonusPackagesSkeletonDiv {
  height: 56px;
  display: flex;
  border-radius: 0;

  span {
    border-radius: 0;
    line-height: 0;
    height: 100%;
    width: 100%;
  }


  @media screen and (max-width: 650px) {
    height: 45px;
  }

}

.bonusBodyContainer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: 650px) {
    padding: 1.25rem 1rem 1.25rem !important;
  }
}

.bonusErrorShow {
  position: absolute;
  bottom: -25px;
  left: 0;

  @media screen and (max-width: 650px) {
    font-size: 75%;
  }

  @media screen and (max-width: 400px) {
    font-size: 65%;
  }

}

.depositPackagesSubText {
  font: normal normal 400 12px/125% "SourceSansPro";
}

.bonusPromoCodeContainer {
  margin: 10px 0;
  padding: 0 40px;

  @media screen and (max-width: 650px) {
    padding: 0;
  }
}


.bonusButtonContainer {

  @media screen and (max-width: 650px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
