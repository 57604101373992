.container {
  width: 100%;
  height: 125px;
  background: rgba(134, 149, 169, 1);
  @media (max-width: 525px) {
    height: 192px;
    position: relative;
    margin-bottom: 35px;
  }
  @media (max-width: 550px) {
    height: 150px;
  }


  @media (max-width: 549px) {
    height: 200px;
  }


  @media (max-width: 320px) {
    height: 220px;
  }


}

.list_title {
  font-weight: 700;
  font-size: 15.5px;
  line-height: 18px;
  margin-top: 9px;
  margin-bottom: 5px;
}

.list_item {
  font-weight: 400;
  font-size: 13.5px;
  line-height: 15px;
  margin-bottom: 5px;
}

.ul {
  list-style-type: disc;
}

.price_container {
  width: 100%;
  max-width: 150px;
  height: 100%;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
  @media (max-width: 525px) {
    max-width: 50%;
    height: 44px;
    font-size: 22.5px;
    line-height: 26px;
  }
}

.arrow_icon {
  height: 11px;
  width: 11px;
  font-weight: bold;
  fill: #ffffff;
  transform: scale(1.1);
}

.price_row {
  width: 100%;
  height: 39px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 525px) {
    height: 88px;
    flex-wrap: wrap;
  }
}

.price_label {
  font-weight: 400;
  font-size: 12.5px;
  line-height: 14px;
  margin-left: 10px;
  @media (max-width: 525px) {
    font-size: 13.5px;
    line-height: 16px;
  }
}

.money_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBA72A;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  .text{
    color: #000000;
  }

  @media (max-width: 525px) {
    max-width: 50%;
    height: 44px;
  }
}

.price_wrapper_left {
  display: flex;
  align-items: center;
}

.money_icon {
  width: 27.5px;
  height: 25.5px;
  color: #000000;
  @media (max-width: 525px) {
    width: 30px;
    height: 29px;
  }
}

.text {
  font-weight: 700;
  font-size: 15.5px;
  line-height: 18px;
  margin-left: 12px;
  color: rgba(255, 255, 255, 1);
}

.buvip {
  width: 100%;
  max-width: 186px;
  height: 100%;
  border-right: 1px solid white;
  @media (max-width: 525px) {
    max-width: 100%;
    height: 44px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}

.button_container {
  height: 78px;
  padding-left: 29px;
  padding-right: 29px;
  @media (max-width: 550px) {
    height: 93px;
  }

  @media (max-width: 440px) {
    height: 100px;
  }

  @media (max-width: 320px) {
    height: 120px;
  }


}

.list_container {
  height: 100%;
}

.button {
  width: 211px;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 525px) {
    position: absolute;
    width: 230px;
    height: 45px;
    font-size: 15px;
    line-height: 20px;


    left: calc(50%);
    bottom: -15px;
    transform: translateX(-50%);
    margin: 0;
  }
}
