.poster-icon {
  position: relative;
  width: 100%; // This can be 100% to fill the container or any other width
  overflow: hidden; // This ensures that the image doesn't break the layout

  &::after {
    content: '';
    display: block;
    padding-top: 56.25%; // This is for an aspect ratio of 16:9 (9 / 16 * 100)
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; // This will ensure the image covers the area without stretching
  }
}