.container {
  width: 520px !important;
}

.card_money_button_container {
  padding-left: 27px;
  padding-right: 27px;
  background: #e0e5ec;
  padding-top: 13px;
}

.title_container {
  height: 60px;
  background: #ffffff;
  border-radius: 7px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 19.5px;
  line-height: 22px;
  letter-spacing: 2px;
  font-weight: 700;
  @media (max-width: 410px) {
    width: 90%;
    text-align: center;
  }
}

.saved_card_container {
  width: 100%;
}

.saved_card_wrapper {
  border-width: 2px;
  border-color: transparent;
  background-color: #ffffff;
  width: 464px;
  height: 73px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 14px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

.method_saved_card {
  border-color: #2fb782;
}

.payment_method_title {
  font-weight: 400;
  font-size: 14.5px;
  line-height: 16.5px;
  margin-bottom: 5px;
}

.select_input {
  width: 100%;
  height: 45px;
  background-color: rgba(170, 170, 170, 0.1);
  border-radius: 43.5px;
  border: 1px solid rgba(48, 48, 48, 0.2);
  text-indent: 14px;
}

.select_option {
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
}

.method_amount_container {
  width: 100%;
  display: flex;
  margin-top: 22px;
}

.method_container {
  width: 50%;
  @media (max-width: 780px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.method_title {
  font-weight: 400;
  font-size: 14.5px;
  line-height: 16.5px;
}

.amount_container {
  width: 50%;
  @media (max-width: 780px) {
    width: 100%;
  }
}

.button {
  background-color: #c8241e;
}

.age_confirmation {
  font-weight: 400;
  font-size: 12.5px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  text-align: center;
}

.next_back_container {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 410px) {
    margin-top: 10px;
    flex-direction: column-reverse;
  }
}

.next_button {
  background: #2fb782;
  color: #ffffff;
  border-radius: 44px;
  width: 227px;
  height: 45px;
  @media (max-width: 780px) {
    width: 143px;
    height: 45px;
  }
}

.back_button {
  border: 2px solid #c8241e !important;
  border-radius: 43.5px;
  color: #c8241e;
  width: 227px;
  height: 45px;
  @media (max-width: 780px) {
    width: 143px;
    height: 45px;
  }
}

.disabled_button {
  opacity: 0.5;
}

.mobile_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
