.promo-slider {
  &:hover {
    .swiper-button-next {
      opacity: 1;
    }
    .swiper-button-prev {
      opacity: 1;
    }
  }
  .swiper-button-next {
    margin: 0 !important;
    height: 100% !important;
    top: 0 !important;
    right: 0 !important;
    width: 50px !important;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.15s;
  }
  .swiper-button-prev {
    margin: 0 !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    width: 50px !important;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.15s;
  }
}
#poster-slider {
  .swiper-slide {
    overflow: hidden !important;
    img {
      transition: all 0.2s;
      backface-visibility: hidden;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
    &:hover {
      img {
        transform: scale(1.05) !important;
        backface-visibility: hidden !important;
      }
    }
  }
}
.swiper-slide {
  height: auto !important;
  img {
    transition: all 0.2s;
    backface-visibility: hidden !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }
  .play-icon-container {
    position: absolute;
    display: block;
    bottom: 0px;
    left: 20px;
    transition: all 0.2s;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.1);
    img {
      width: 70px;
    }
  }
  &:hover {
    .play-icon-container {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
}
