.container,
.container_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 7px;
}

.container_row {
  flex-direction: row;
  @media (max-width: 440px) {
    flex-direction: column;
  }
}

.title_img_container {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discreet_img {
  width: 26px;
  height: 26px;
}

.beate_title,
.beate_title_row {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
}

.beate_title_row {
  margin-left: 5px;
}
