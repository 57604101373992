

.promoPlanPriceText {
  font-size: 18px;

  @media screen and (max-width: 650px) {
    font-size: 14px;
  }


  @media screen and (max-width: 400px) {
    font-size: 12px;
  }

}


.promoPlanPriceContainer {
  height: 56px;

  @media screen and (max-width: 650px) {
    height: 47px;
  }
}

.promoSkeletonDiv {
  height: 56px;
  display: flex;
  border-radius: 0;

  span {
    border-radius: 0;
    line-height: 0;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 650px) {
    height: 45px;
  }
}

.promoErrorShow {
  position: absolute;
  bottom: -25px;
  left: 0;

  @media screen and (max-width: 650px) {
    font-size: 75%;
  }

  @media screen and (max-width: 400px) {
    font-size: 65%;
  }
}