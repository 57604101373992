.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 17px;
  max-width: 525px;
  @media (max-width: 480px) {
    max-width: 320px;
    height: 527px;
  }
}

.warning_img {
  height: 67px;
  width: 200px;
}

.title_container {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    height: 31px;
    margin-bottom: 10px;
  }
}

.title {
  font-weight: 700;
  font-size: 28px;
  @media (max-width: 480px) {
    font-size: 19.5px;
    line-height: 22.5px;
  }
}

.vip_img {
  width: 70px;
}

.wrapper {
  width: 100%;
  margin-bottom: 1rem;
}

.or {
  font-size: 12px;
  line-height: 14px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
