body {
  font-family: $siteBaseFont;
  background-color: #fff;
  box-sizing: border-box;
}

.hor-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0;
}
.work-sans-font {
  font-family: $siteSectionFont;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
button {
  &:focus {
    outline: none;
  }
}


video {
  &:focus {
    outline: none;
  }
}

.z-50 {
  z-index: 50;
}
.z-100 {
  z-index: 100;
}

.z-9999 {
  z-index: 9999;
}

.heidelpaySandboxNotify {
  display: none !important;
}
.lazyload-wrapper {
  height: 100%;
}
.main-screen {
  height: 100%;
  width: 100%;
}
#main-section {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1439px) {
    padding-top: 5rem;
  }
  @media (max-width: 1023px) {
    padding-top: 2rem;
  }

  @media (max-width: 450px) {
    padding-top: 2rem;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @media  (min-width: 1024px) and  (max-width: 1439px) and (orientation: landscape) {
    margin-top: 4rem !important;
  }

  @media  (min-width: 981px) and  (max-width: 1025px) and (orientation: landscape) {
    padding-top: 5rem;
    margin-top: 4rem !important;
  }

  @media  (max-width: 900px) and (orientation: landscape) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }


}
.main-container {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.button-price-side {
  width: auto;
  min-width: 110px;
  display: inline;
}
.button-left-side {
  width: auto;
  min-width: 90px;
  display: inline;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.price-button {
  height: 60px;
}

.btnInnerSpinner:before {
  content: '';
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: transparent;
  animation: elementSpinner 0.9s linear infinite;
}
.blackSpinner:before {
  border: 3px solid #000;
  border-top-color: transparent;
}
.price-promo-icon {
  right: -38px;
  top: -20px;
}
@keyframes elementSpinner {
  to {
    transform: rotate(360deg);
  }
}

.search-input-container {
  position: relative;
  min-width: 245px;

  &.disabled {
    .search-input {
      opacity: 0.75;
      cursor: not-allowed;
    }

    i {
      opacity: 0.75 !important;
    }
  }

  .prefix-icon {
    position: absolute;
    left: 6px;
  }

  .suffix-icon {
    position: absolute;
    right: 6px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &:active {
      outline: none;
    }
  }

  .search-input {
    padding: 5px 30px;
    height: 40px;
    width: auto;
    min-width: 245px;
    border-radius: 8px;

    @media (max-width: 650px) {
      width: 100%;
    }
  }
}

.btn-outlined {
  @apply bg-transparent border hover:border-transparent;
}
.btn-bordered {
  @apply border;
}
.btn-rounded {
  @apply rounded;
}

.btn-disabled {
  @apply opacity-50 cursor-not-allowed;
}

.btn {
  min-width: 80px;
  @apply flex gap-1 font-normal py-2 px-3 items-center justify-center text-black;

  &.btn-outlined {
    border-color: #C7C5C5;
    @apply bg-transparent;
  }

}


.btn-blue {
  @apply bg-blue-500 text-white;
  &.btn-outlined {
    @apply bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white border-blue-500 ;
  }
  &.btn-bordered {
    @apply hover:bg-blue-700 border-blue-700
  }
  &:hover {
    @apply bg-blue-700;
  }
}

.btn-yellow {
  @apply bg-mainYellow text-black;
  &.btn-outlined {
    @apply bg-transparent hover:bg-mainYellowOpacity text-blue-700 hover:text-white border-mainYellow ;
  }
  &.btn-bordered {
    @apply hover:bg-mainYellow border-mainYellow
  }
  &:hover {
    @apply bg-mainYellow;
  }
}

.react-responsive-modal-modal {

  @media (max-width: 768px) {
    width: 95%;
  }
}



