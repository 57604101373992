
.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 40px;
  width: 100%;
  min-height: 700px;
  padding: 30px 0;




  .icon {
    display: flex;
    justify-content: center;
    align-content: center;
    opacity: 0.3;
    font-size: 150px;
    min-height: 180px;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 15px;


    .vip-button {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.04em;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 525px) {
        font-size: 15px;
        line-height: 20px;
      }
    }


  }

  .children-container {
    display:flex;
    justify-content: center;
    align-content: center;
  }

}


.no-data-recommendations-movies {
  width: 100%;
  min-height: 300px;


  .slider {
    width: 100%;
    display: block;
    min-height: 300px;
    margin: 15px 0;

  }

  .poster-list-item {
    aspect-ratio: 360 / 517 !important;
  }
}
