.register-modal {
  font-weight: 600;
  width: 100% !important;
  max-width: 330px !important;
  min-width: 330px;
}
.cancel-sub-modal {
  width: 100% !important;
  max-width: 400px !important;
}
.message-modal {
  width: 100% !important;
  max-width: 600px !important;
}
.login-modal {
  font-weight: 600;
}
.form-buttons {
  font-size: 14px;

  &.disabled {
    opacity: 0.5;
  }
}
.react-responsive-modal-overlay {
  padding-top: 30px !important;
  z-index: 100 !important;
}

.react-responsive-modal-modal {
  font-weight: 600 !important;
  max-width: 100% !important;
}
.react-responsive-modal-closeButton {
  background-color: #fff !important;
  border-radius: 5px;
  width: 18px;
  border: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }
  svg {
    width: 18px;
    border: 0;
    height: 18px;
    &:focus {
      outline: none;
    }
  }
}
#custom-close-button {
  background-color: transparent !important;
  border-radius: 5px;
  width: 15px;
  height: 15px;
  border: 0;
  position: absolute;
  right: -22px;
  color: red;
  top: -22px;
  &:focus {
    outline: none;
  }
  svg {
    border: 0;
    width: 15px;
    height: 15px;
    color: red;
    &:focus {
      outline: none;
    }
  }
}
.change-pwd-form {
  width: 100%;
  max-width: 500px;
}
.player-modal {
  box-shadow: none !important;
  padding: 0 !important;
  width: 100%;
  max-width: 1200px;
}
#deposit-modal {
  width: 400px;
  max-width: 100%;
  #payment-method {
    max-height: 60px;
    .sepa-logo {
      width: 100%;
      max-width: 90px;
    }
  }
}
#payment-modal {
  width: 400px;
  max-width: 100%;
}
#subscription-prices-modal {
  max-width: 960px;
}
#svod-modal {
  width: 100vw !important;
}

#phone-verify-modal {
  width: 350px;
  max-width: 100%;
}
#cancel-sub-modal {
  width: 550px !important;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .form-header {
    position: relative;

    p {
      font-weight: normal;
      opacity: 0.75;
      line-height: normal;
      text-align: center;
    }
  }

  .form {
    flex: 2;
    display: flex;
    flex-direction: column;
  }


  .cancel-sub-special-offer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;

    p {
      font-weight: 600;
      font-size: 22px;
    }
  }


  .form-buttons {
    padding: 0.75rem 0.75rem;
    font-weight: normal;
    font-size: 16px !important;

    &.back-button {
      min-width: 40px;
      max-width: 115px;
      position: absolute;
      top: -10px;
      left: 0;
      margin: 0;
      border: 1px solid #000;
      background-color: #fff;
      padding: 10px;
      font: normal normal normal 20px/125% "SourceSansPro";

      &.no-text {
        border-color: $blue-gray-bg;
        display:  flex;
        justify-content: center;
        max-width: 35px !important;
        border-radius: 50%;
        height: 35px;
      }

      @media screen and (max-width: 650px) {
        max-width: 75px;
        padding: 5px;
        top: 20px;

        .label {
          font-size: 14px;
        }

      }

    }
  }

}
.subscription-price-item {
  min-width: 200px;
}
.subscribe-modal {
  position: fixed;
  bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 95%;
  max-width: 500px;
  z-index: 200;
  background-color: #fff;
  border-radius: 7px;
  transition: right ease 600ms;
  p {
    font-size: 14px;
  }
  button {
    font-size: 14px;
  }
  .subscription-close {
    position: absolute;
    top: 4px;
    right: 8px;
  }
}

.image-modal-content {
  .image-slider-left {
    top: 50%;
    transform: translateY(-50%);
    left: -30px;
  }
  .image-slider-right {
    top: 50%;
    transform: translateY(-50%);
    right: -30px;
  }
  img {
    width: 100%;
    max-width: 600px;
  }
}

/* examples/custom-animation.css */
@keyframes customEnterAnimation {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
@keyframes customLeaveAnimation {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}

/* examples/custom-animation.css */
@keyframes customEnterAnimationImage {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
@keyframes customLeaveAnimationImage {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}

@media (max-width: 767px) {
  .subscribe-modal-show {
    right: 10px;
    left: 10px;
    margin: 0 auto;
  }
  .subscribe-modal-hide {
    right: -600px;
  }
}
@media (min-width: 768px) {
  .subscribe-modal {
    p {
      font-size: 16px;
    }
    button {
      font-size: 16px;
    }
  }
  .subscribe-modal-show {
    right: 10px;

    p {
      font-size: 16px;
    }
    button {
      font-size: 16px;
    }
  }
  .subscribe-modal-hide {
    right: -600px;
  }
  #deposit-modal {
    width: 600px;
    max-width: 600px;
    #payment-method {
      max-height: 65px;
      .sepa-logo {
        width: 100%;
        max-width: 100px;
      }
    }
  }
}
@media (min-width: 1440px) {
  #deposit-modal {
    max-width: 600px;
    #payment-method {
      max-height: 65px;
      .sepa-logo {
        width: 100%;
        max-width: 100px;
      }
    }
  }
}

@media (max-width: 410px) {
  .register-modal {
    min-width: 270px;
  }
}

@media (max-width: 340px) {
  .register-modal {
    min-width: 250px;
  }
}


.registration-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 900px;
    max-width: 900px;
    min-height: 94vh;
    max-height: 94vh;
    background: white;
    user-select: none;
    font-family: "SourceSansPro";
    position: relative;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /*
    box-shadow:
            rgba(0, 0, 0, 0.7) 0px 1px 2px,
            rgba(0, 0, 0, 0.7) 0px 2px 4px,
            rgba(0, 0, 0, 0.7) 0px 4px 8px,
            rgba(0, 0, 0, 0.7) 0px 8px 16px,
            rgba(0, 0, 0, 0.7) 0px 16px 32px,
            rgba(0, 0, 0, 0.7) 0px 32px 64px;
    */



  @media screen and (max-width: 1024px) {
    min-width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    max-height: 100%;
    padding: 1rem !important;
    overflow-y: hidden;
    border-radius: 0;
  }

   @media screen and (min-width: 1921px) {
     height: 850px;
     min-height: 850px;
     max-height: 850px;
   }

  .login-form-container {
    width: 450px;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 2;


    @media screen and (max-width: 650px) {
      min-width: 99%;
      width: 100vw;
      max-width: 99%;
      height: 100vh;
    }


    .form-content {
      flex: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 25px;

      @media screen and (max-width: 650px) {
        padding: 0;
      }

      form {
        width: 100%;
      }
    }
  }

   .wizard-container {
     width: 550px;
     height: 100%;
     z-index: 10;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: flex-start;
     flex: 2;


     @media screen and (max-width: 650px) {
       min-width: 99%;
       width: 100vw;
       max-width: 99%;
     }

     .wizard-content {
       width: 100%;
       height: 100%;
       padding: 15px 0;

     }

     .wizard-form-container {
       display: flex;
       flex-direction: column;
       transition: opacity 0.5s ease-in-out;
       width: 100%;
       height: 100%;
       flex: 2 ;

       .logo-site{
           max-width: 165px;
           max-height: 49px;
       }
     }

     .registration-form {
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

         .password-show {
           top: 50%;
           transform: translateY(-50%);

         }
       }

     input {
       min-height: 40px;
     }

     .registration-form-container {
       flex: 2;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       overflow-y: auto;
       scrollbar-width: thin;


       @media screen and (min-width: 1025px) and (max-width: 1366px) {
         max-height: 500px;
         justify-content: flex-start;
       }


       @media screen and (min-width: 1367px) and (max-width: 1440px) {
         max-height: 620px;
         justify-content: flex-start;
       }


     }

   }

  .terms-conditions-checkbox {
    width: 18px;
    min-height: 18px !important;
    max-height: 18px;
  }




  .form-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 14px;
    font: normal normal 600 20px/125% "SourceSansPro";
    position: relative;

    .btnInnerSpinner {
      &:before {
        top: 10px;
      }
    }

    &.no-text {
      max-width: 45px !important;
    }

    &.registration-back-button {
      min-width: 40px;
      max-width: 115px;
      position: absolute;
      top: 10px;
      left: 10px;
      margin: 0;
      border: 1px solid #000;
      background-color: #fff;
      padding: 10px;
      font: normal normal normal 20px/125% "SourceSansPro";

      &.no-text {
        border-color: $blue-gray-bg;
        display:  flex;
        justify-content: center;
        max-width: 35px !important;
        border-radius: 50%;
        height: 35px;
      }

      @media screen and (max-width: 650px) {
        max-width: 75px;
        padding: 5px;
        top: 20px;

        .label {
          font-size: 14px;
        }

      }

    }

    .label {
      flex: 2;

      @media screen and (max-width: 360px) {
          font-size: 13px;
      }
    }

    &.bg-green {
      background-color: $green-bg;
    }

    &.bg-orange-800 {
      background-color: #EEAE3D;
    }

    &.bg-fuchsia-800 {
      background-color: #8C36AB;
    }

    &.bg-slate-400 {
      background-color: #8695A9;
    }

    &.bg-gray-666 {
      background-color: #666666;
    }


    @media screen and (max-width: 650px) {
      font-size: 18px !important;
    }

    @media screen and (max-width: 400px) {
      font-size: 15px !important;
    }

  }

}
