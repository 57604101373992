.title {
  font-weight: 700;
  font-size: 23px;
  line-height: 23px;
}

.studio_title,
.studio_container {
  font-weight: 600;
  font-size: 13.5px;
  line-height: 15px;
  margin-right: 5px;
  margin-left: 5px;
  color: #c42826;
}

.studio_time_container,
.studio_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.studio_container {
}

.time {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
}
