header {
  min-height: 50px;
  transition: ease-in-out 0.1s;

  @media (max-width: 850px) and (orientation: landscape) {
    padding: 0;
    width: 100% !important;
  }

  .active {
    color: $mainRed;
  }
  #navigation {
    p {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
    a {
      width: calc(100% / 9);
    }

    a:last-child p {
      border-right: none;
    }
    .active {
      color: $mainRed;
      border-bottom: 1px solid $mainRed;
    }
    .active.text-mainGreenDark {
      color: $mainGreenDark;
      border-bottom: 1px solid $mainGreenDark;
    }
  }

  .header-top-bar {
    background-color: $mainGray1;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    z-index: 100;
  }
  nav {
    font-weight: 600;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: all 0.3s ease-in-out;
    position: relative;
    background-color: #fff;
  }

  .site-logo {
    width: 260px;
  }
  .header-deposit-text {
    white-space: nowrap;
  }
  .mobile-header {
    height: 50px;
    i {
      line-height: 50px;
    }

    @media (max-width: 650px) {
      max-width: 100vw;
    }

  }
  .side-nav {
    transition: left 0.3s, opacity 0.6s;
    position: absolute;
    max-width: 260px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 8px;
  }

  .beateuhse-navigation {

    @media  (max-width: 1023px) and (orientation: landscape) {
      overflow-y: scroll;
      max-height: calc(100vh - 50px);
    }

    @media  (max-width: 900px) and (orientation: landscape) {
      overflow-y: scroll;
      max-height: calc(100vh - 100px);
    }

    @media  (max-width: 850px) and (orientation: landscape) {
      overflow-y: scroll;
      max-height: calc(100vh - 130px);
    }

  }

  .nav-show {
    left: 0;
    opacity: 1;
  }
  .nav-hide {
    left: -100%;
    opacity: 0;
  }
  .mobile-search {
    position: absolute;
    transition: all 0.4s;

    @media (max-width: 650px) {
      max-width: 100vw;
    }

  }
  .search-hide {
    opacity: 0;
    left: -100%;
  }
  .search-show {
    opacity: 1;
    left: 0;
  }
  .search-field-big {
    .search-input-mobile {
      padding-left: 8px;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
    }
    .search-input-desktop {
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
      padding-left: 8px;
      background-color: rgb(249, 249, 249);
      border: 0.5px solid rgba(0, 0, 0, 0.2);
    }
    .search-type-mobile {
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
      padding-left: 8px;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      height: 34px;
      width: 100%;
      min-width: 140px;
    }
    .search-type-desktop {
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
      padding-left: 8px;
      background-color: rgb(249, 249, 249);
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      height: 34px;
      width: auto;
      min-width: 140px;
    }
    button {
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
    }
  }
  .mobile-logo {
    top: 50%;
    transform: translateY(-50%);
  }
}
.back-to-top {
  transition: all 0.3s;
  z-index: 100;
  img {
    width: 50px;
    transform: rotate(270deg);
  }

  @media (min-width: 801px )and  (max-width: 900px)  and (orientation: landscape) {
    position: fixed;
    bottom: 15px !important;
    right: 0px !important;
    margin: 0;
  }


}
.show-btt {
  transform: scale(1);
}
.hide-btt {
  transform: scale(0);
}
.search-suggestions-desktop {
  background-color: #fff;
  z-index: 110;
  top: 34px;
  left: 0px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
.search-suggestions-mobile {
  background-color: #fff;
  z-index: 110;
  top: 35px;
  left: 0px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.suggestion-list {
  height: 48px;
  white-space: nowrap;

  .suggestion-placeholder {
    height: 48px;
  }
}
