// Source Sans Pro

@font-face {
  font-family: 'SourceSansPro';
  src: url('../../fonts/SourceSansPro-Light.ttf');
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../../fonts/SourceSansPro-Regular.ttf');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../../fonts/SourceSansPro-SemiBold.ttf');
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../../fonts/SourceSansPro-Bold.ttf');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

// Work sans

@font-face {
  font-family: 'WorkSans';
  src: url('../../fonts/Work_Sans/static/WorkSans-Light.ttf');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../fonts/Work_Sans/static/WorkSans-Regular.ttf');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../fonts/Work_Sans/static/WorkSans-SemiBold.ttf');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../fonts/Work_Sans/static/WorkSans-Bold.ttf');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
