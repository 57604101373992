.wishlist_button {
  width: 166px;
  height: 30px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.02em;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background: #aaaaaa;

}

@keyframes elementSpinner {
  to {
    transform: rotate(360deg);
  }
}

.wishlist_button_spinner {
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid white;
    border-top-color: transparent;
    animation: elementSpinner 0.9s linear infinite;
  }

}


.active_wishlist_button {
  background: #c62628;
  width: 186px;
}

.text {
  margin-left: 10px;
}
