#payment-success-page {
  .payment-success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    gap: 15px;
  }


  .payment-error-icons {
    max-width: 300px;
    max-height: 300px;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: fill;
    }
  }

}
