
.top100-navigation {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  z-index: 19;


  @media (min-width: 1020px) {
    //top: 144px;
    top: 96px;
  }


  @media (max-width: 450px) {
   padding: 0 5px !important;
   justify-content: center !important;
   gap: 5px;
  }


}

.top100-nav-item {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  color: black;

  &:hover {
    background: linear-gradient(0deg, transparentize(rgba(255,10,10,1), 0.95) 0%, transparentize(rgba(153,116,0,1),0.95) 100%);
    border-bottom: 2px solid $newRead;
  }

  &.active {
    color: $newRead;
    border-bottom: 2px solid $newRead;
  }

  @media (max-width: 450px) {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      padding: 5px;
    }
  }
}

.top100-container {
  padding: 20px 0;

  @media (min-width: 801px) {
    margin-top: 3rem !important;
  }

  @media (max-width: 800px) {
    margin-top: 3rem !important;
  }

  @media (min-width: 1367px) {
    margin-top: 5rem !important;
  }

}

.top100-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 15px;

  .top100-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

    .top100-title {
      flex: 2;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: initial;
      font-size: 20px;

      @media (max-width: 650px) {
        font-size: 16px;
        max-width: calc(100vw - 60px);
      }
    }

    .top100-title-no-button {
      flex: 2;
      max-width: 100%;
      display: initial;
      font-size: 20px;

      @media (max-width: 650px) {
        font-size: 16px;
      }

    }
  }

  .top100-list-context {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .poster-list-item {
      margin-bottom: 20px;

      .react-loading-skeleton {
        object-fit: fill !important;
        aspect-ratio: 360 / 517 !important;
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
        border-radius: 8px;
      }

    }

    @media (min-width: 768px) {
      .poster-list-item {
        width: calc((100% / 3) - 30px);
      }
    }

    @media (min-width: 950px) {
      .poster-list-item {
        width: calc((100% / 4) - 30px);
      }
    }

    @media (min-width: 1280px) {
      .poster-list-item {
        width: calc((100% / 6) - 30px);
      }
    }

  }
}
