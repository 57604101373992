#movies {
  width: calc(100% + 1.25rem);
}
.poster-item-bottom-info {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  span {
    display: inline-block;
    width: 100%;
    line-height: 1em;
    max-height: 2em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}


.new-icon {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: -13px;
  right: -13px;
  width: 70px;

  @media (max-width: 650px) {
    width: 40px;
    top: -8px;
    right: -8px;
  }

  @media (max-width: 1366px) {
    width: 60px;
    top: -8px;
    right: -8px;
  }

}



.reduced-icon {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: -13px;
  left: -13px;
  width: 70px;
  transform: rotate(270deg);

  @media (max-width: 650px) {
    width: 40px;
    top: -8px;
    left: -8px;
  }

  @media (max-width: 1366px) {
    width: 60px;
    top: -8px;
    left: -8px;
  }

}


.audio-vip-top100-section {
  position: absolute;
  top: 60%;
  transform: translateY(-60%);
  right: -13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 145px;
  gap: 5px;
  z-index: 10;

  @media (max-width: 650px) {
    top: 80%;
    transform: translateY(-80%);
    right: -8px;
  }

  @media (max-width: 1366px) {
    right: -8px;
  }

  .flag {
    width: 45px;
    height: 45px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    @media (max-width: 650px) {
      width: 40px;
      height: 40px;
    }

  }
}


.reduced-icon-german {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: -20px;
  left: -17px;
  width: 122px;
}


.poster-list-item {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  margin-left: 15px;
  margin-right: 15px;
  z-index: 10;
  transition: ease-in-out 0.3s;
  position: relative;
  border-radius: 8px;

  .cover-image {
    border: 1px solid transparent;
    box-shadow: 1px 1px 12px -6px grey;
    border-radius: 8px;
    position: relative;
  }

  .poster-icon {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill !important;
    aspect-ratio: 360 / 517 !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    border-radius: 8px;
  }
  .german-audio {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 40px;
    height: 40px;
  }


  .hover-effect-container {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 10;


    .play-icon {
      flex: 2;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;

      img {
        width: 50px !important;
        height: 50px !important;
      }

    }

    .poster-item-title {
      margin: 0;
      font-size: 18px;
      line-height: 125%;
      opacity: 0;
      text-align: center;
      color: #ffffff;
      letter-spacing: 0.4px;
      text-shadow: 1px 1px 1px #333333;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      overflow-x: hidden;
      max-width: 100%;
      padding: 16px 4px;
      text-overflow: ellipsis;
    }

  }

  .play-icon-container {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(0.1);
    transition: opacity .3s ease-out;
    z-index: 100;

    img {
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);
    }
  }



  &:hover {
    @media only screen and (max-width: 767px) {
      .poster-icon {
        opacity: 1;
      }
      .play-icon-container {
        opacity: 0 !important;
      }

      .hover-effect-container {
        display: none !important;
      }

      img {
        opacity: 1 !important;
      }
    }

    .hover-effect-container {
      display: flex;
    }

    .hover-effect {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
    }

    img {
      zoom: 1;
    }

    .play-icon-container {
      z-index: 100;
      img {
        backdrop-filter: none;
        filter: alpha(opacity=100) !important;
        -webkit-opacity: 1 !important;
        opacity: 1 !important;
        z-index: 100;
      }
    }

    .poster-item-title {
      opacity: 1;
      -webkit-transition-delay: 0.3s;
      transition-delay: 0.3s;
      z-index: 1000;
    }

      .play-icon-container {
        opacity: 1;
        transform: scale(1);
        -webkit-transition-delay: 0.3s;
        transition-delay: 0.3s;
      }

  }
}
.poster-list-item {
  width: calc((100% / 2) - 30px);
}


.hover-effect-container {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  border-radius: 8px;

  .hover-effect {
    box-sizing: border-box;
    transition: all 0.4s ease;

  }

  .play-icon {
    flex: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;

    img {
      width: 40px !important;
      height: 40px !important;
    }

  }

}


.recomended-slider {
  position: relative;

  .swiper {
   // padding: 15px;
   // max-width: calc(100% - 50px);
    position: unset;
  }
  .swiper-slide-item {
    border-radius: 4px;
    transition: ease-in-out 0.2s;

    .lazy-load-image-background {
      width: 100%;
    }

    .recomended-poster-item-title {
      margin: 0;
      font-size: 18px;
      line-height: 125%;
      opacity: 0;
      text-align: center;
      color: #ffffff;
      letter-spacing: 0.4px;
      text-shadow: 1px 1px 1px #333333;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      overflow-x: hidden;
      max-width: 100%;
      padding: 16px 4px;
      text-overflow: ellipsis;
    }


    img {
      backface-visibility: hidden;
      vertical-align: top;
    }
    * {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }


    &:hover {
      @media only screen and (max-width: 767px) {
        .hover-effect-container {
          display: none !important;
        }
        .play-icon-container {
          opacity: 0 !important;
        }

        img {
          opacity: 1 !important;
        }
      }

      .poster-list-item-recommended {
        border-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      }

      .hover-effect-container {
        display: flex;
        z-index: 10;
      }

      .hover-effect {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        background: rgb(255,255,255);
        background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
      }

      .recomended-poster-item-title {
        opacity: 1;
        -webkit-transition-delay: 0.3s;
        transition-delay: 0.3s;
        z-index: 1000;
      }


      img {
        zoom: 1;
      }

      .play-icon-container {
        z-index: 100;
        img {
          backdrop-filter: none;
          filter: alpha(opacity=100) !important;
          -webkit-opacity: 1 !important;
          opacity: 1 !important;
          z-index: 100;
        }
      }


     // backdrop-filter: blur(5px);
     // border-radius: 3px;
     // border: 1px solid rgba(255, 255, 255, 0.69);
     // transform: scale(1.05);
      //box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
     // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }



  }

  &:hover {
    .swiper-button-next {
      opacity: 1;
    }
    .swiper-button-prev {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 1024px){
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0;
    }
  }

  .swiper-button-next {
    margin: 0 !important;
    height: 100% !important;
    top: 0 !important;
    right: 0 !important;
    width: 50px !important;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.15s;
  }
  .swiper-button-prev {
    margin: 0 !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    width: 50px !important;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.15s;
  }
  .recomended-poster-item-bottom-info {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;

    span {
      display: inline-block;
      width: 100%;
      line-height: 1em;
      max-height: 2em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .poster-icon {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill !important;
    max-width: 100%;
    max-height: 100%;
  }
}

.poster-list-item-recommended {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 8px;
  border: 1px solid transparent;


  .hover-effect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
  }

  .recomended-poster-item-title {
    margin: 0;
    font-size: 18px;
    line-height: 125%;
    opacity: 0;
    text-align: center;
    color: #ffffff;
    letter-spacing: 0.4px;
    text-shadow: 1px 1px 1px #333333;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    overflow-x: hidden;
    max-width: 100%;
    padding: 16px 4px;
    text-overflow: ellipsis;
    z-index: 11;
  }



  &:hover {
    .hover-effect-container {
      display: flex;
      justify-content: flex-end;
    }

    .recomended-poster-item-title {
      opacity: 1;
    }
  }

  .cover-image {
    border: 1px solid transparent;
    //box-shadow: 1px 1px 12px -6px grey;
    border-radius: 8px;
    position: relative;
  }

  .poster-icon {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: fill !important;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;

    @media (max-width: 650px) {
      display: flex !important;
      justify-content: center;
      align-content: center;
    }

    img {
      border-radius: 8px;
    }

  }

  // margin-left: 10px;
  // margin-right: 10px;
  .recomended-poster-item-bottom-info {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;

    span {
      display: inline-block;
      width: 100%;
      line-height: 1em;
      max-height: 2em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  .poster-icon {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill !important;
  }
  .german-audio {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 40px;
    height: 40px;
  }
  .play-icon-container {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.1);

    img {
      position: absolute;
      width: 50px !important;
      height: 50px !important;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);
    }
  }
  // &:hover {
  //   .play-icon-container {
  //     visibility: visible;
  //     opacity: 1;
  //     transform: scale(0.1);
  //   }
  // }
}
.poster-list-item-recommended {
  // width: calc((100% / 2) - 1.25rem);
}
.seo-text-container-mobile {
  position: absolute;
  top: 30%;
  p,
  span {
    line-height: 1.2em;
  }
}
.seo-gradient-wraper {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 20px;
  z-index: 10;
  background: rgb(2, 0, 36);
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 36%, rgba(255, 0, 0, 0) 100%);
}
.html-text-container {
  overflow: hidden;
}
.seo-text-container-tablet {
  position: absolute;
  top: 30%;
}
.read-more-btn {
  color: #fff;
  position: absolute;
  bottom: -10px;
  right: 10px;
  z-index: 12;
}

.seo-background-image {
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 380px) {
  .html-text-container {
    max-height: 6em !important;
  }
}

@media (min-width: 381px) {
  .html-text-container {
    max-height: 6em !important;
  }
}
@media (min-width: 640px) {
  .poster-list-item {
    width: calc((100% / 3) - 30px);
  }

  .poster-list-promotion {
    width: calc((100% / 3) - 30px) !important;
  }
  .html-text-container {
    max-height: 8em !important;
  }
  .read-more-btn {
    right: 20px;
  }
}
@media (min-width: 768px) {
  .poster-list-item {
    width: calc((100% / 4) - 30px);
  }
  .poster-list-item-recommended {
    width: calc((100% / 3) - 1.5rem);
  }

  .poster-list-promotion {
    width: calc((100% / 4) - 30px) !important;
  }
  .html-text-container {
    max-height: 10em !important;
  }
  .read-more-btn {
    right: 40px;
  }
  .seo-gradient-wraper {
    width: 85%;
  }
}

@media (min-width: 1024px) {
  .poster-list-item {
    width: calc((100% / 5) - 30px);
  }
  .poster-list-item-recommended {
    width: calc((100% / 5) - 1.5rem);
  }

  .poster-list-promotion {
    width: calc((100% / 5) - 30px) !important;
  }
  .html-text-container {
    max-height: 100vh !important;
  }
}

@media (min-width: 1280px) {
  .poster-list-item {
    width: calc((100% / 7) - 30px);
  }
  .poster-list-item-recommended {
    width: calc((100% / 6) - 1.5rem);
  }
  .poster-list-promotion {
    width: calc((100% / 7) - 30px) !important;
  }
  .home-poster-list {
    width: calc((100% / 7) - 1.5rem);
  }
}

@media (min-width: 1440px) {
  .poster-list-item {
    width: calc((100% / 7) - 30px);
  }
  .poster-list-item-recommended {
    width: calc((100% / 6) - 1.5rem);
  }
  .poster-list-promotion {
    width: calc((100% / 7) - 30px) !important;
  }
}

.skeleton-container {
  .poster-list-item {
    flex: 1;
  }

  @media (min-width: 768px) {
    .poster-list-item {
      min-width: calc((100% / 4) - 30px);
    }
    .poster-list-item-recommended {
      min-width: calc((100% / 3) - 1.5rem);
    }

    .poster-list-promotion {
      min-width: calc((100% / 4) - 30px) !important;
    }
  }


  @media (min-width: 640px) {
    .poster-list-item {
      min-width: calc((100% / 3) - 30px);
    }
    .poster-list-promotion {
      min-width: calc((100% / 3) - 30px) !important;
    }
  }

  @media (min-width: 450px) {
    .poster-list-item {
      min-width: calc((100% / 2) - 30px);
    }

    .poster-list-promotion {
      min-width: calc((100% / 2) - 30px) !important;
    }
  }


  @media (min-width: 1024px) {
    .poster-list-item {
      min-width: calc((100% / 5) - 30px);
    }
    .poster-list-item-recommended {
      min-width: calc((100% / 5) - 1.5rem);
    }
    .poster-list-promotion {
      min-width: calc((100% / 5) - 30px) !important;
    }
  }

  @media (min-width: 1280px) {
    .poster-list-item {
      min-width: calc((100% / 7) - 30px);
    }
    .poster-list-item-recommended {
      min-width: calc((100% / 6) - 1.5rem);
    }
    .poster-list-promotion {
      min-width: calc((100% / 7) - 1.5rem) !important;
    }
    .home-poster-list {
      min-width: calc((100% / 7) - 1.5rem);
    }
  }

  @media (min-width: 1440px) {
    .poster-list-item {
      min-width: calc((100% / 7) - 30px);
    }
    .poster-list-item-recommended {
      min-width: calc((100% / 6) - 1.5rem);
    }
    .poster-list-promotion {
      min-width: calc((100% / 7) - 1.5rem) !important;
    }
  }

}


.movies-skeleton-container {
  .poster-list-item {
    .react-loading-skeleton {
      object-fit: fill !important;
      aspect-ratio: 360 / 517 !important;
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
      border-radius: 8px;
    }
  }
}


