#contact-page {
  max-width: 1000px;
  margin: 0 auto;
  h2 {
    font-size: 28px;
  }
  p {
    color: rgba(0, 0, 0, 0.75);
  }
  a {
    color: #337ab7;
    opacity: 1;
  }
}

#contact-form {
  width: 100%;
  max-width: 450px;
  input {
    background-color: $mainGray1;
  }
  textarea {
    background-color: $mainGray1;
  }
}

#legal_page {
  max-width: 1200px;
}
