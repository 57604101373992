.star-image-container {
  padding-top: 135%;
  background-position: center;
  background-size: cover;
}
.star-gallery-slider {
  .swiper-button-next {
    position: absolute;
    margin: 0 !important;
    top: 50% !important;
    transform: translateY(-50%);
    right: -3px !important;
    width: 30px !important;
    height: 30px !important;
    background-image: url(../../images/stars/arrow-right-round.png) !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    opacity: 0.8;
  }
  .swiper-button-next::after {
    display: none;
  }
  .swiper-button-prev::after {
    display: none;
  }
  .swiper-button-prev {
    margin: 0 !important;
    top: 50% !important;
    transform: translateY(-50%);
    left: -3px !important;
    width: 30px !important;
    height: 30px !important;
    background-image: url(../../images/stars/arrow-left-round.png) !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    opacity: 0.8;
  }
}

.all-stars-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 50px;
  padding: 15px 0;
  left: 0;
  border-top: 1px solid #e2e8f0;
  z-index: 19;

  @media (min-width: 1020px) and (max-width: 1439px) {
    top: 140px;
  }

  @media (min-width: 1440px) {
    top: 96px;
  }




  .all-stars-header-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    width: 100%;

    @media (max-width: 840px) and (orientation: landscape) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 650px) {
      padding-left: 10px;
      padding-right: 10px;
      flex-wrap: wrap;
      gap: 10px;
      z-index: 19;

      .search-input-container {
        min-width: 100%;
        width: 100%;
      }
    }


  }



  .popular-button {
    min-width: 140px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 650px) {
      min-width: 50px;
      border-radius: 100%;
      font-size: 36px;
      padding: 6px;
    }


    @media (max-width: 840px) and (orientation: landscape) {
      min-width: 50px;
      border-radius: 100%;
      font-size: 36px;
      padding: 6px;
    }

  }


  .all-button {
    min-width: 80px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 650px) {
      min-width: 50px;
      border-radius: 100%;
      font-size: 16px;
      padding: 6px;
    }


    @media (max-width: 840px) and (orientation: landscape) {
      min-width: 50px;
      border-radius: 100%;
      font-size: 16px;
      padding: 6px;
    }

  }


  .search-input-container{
    position: relative;
    min-width: 245px;

    &.disabled {
      .search-input {
        opacity: 0.75;
        cursor: not-allowed;
      }

      i {
        opacity: 0.75 !important;
      }
    }

    .prefix-icon {
      position: absolute;
      left: 6px;
    }

    .suffix-icon {
      position: absolute;
      right: 6px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
    }

    .search-input {
      padding: 5px 30px;
      height: 40px;
      width: auto;
      min-width: 245px;
      border-radius: 8px;

      @media (max-width: 650px) {
        width: 100%;
      }
    }

  }

}

.all-stars-container {
  grid-auto-rows: 1fr;
  gap: 20px;


  @media (min-width: 801px) {
    margin-top: 6rem !important;

    .all-star-item-link {
      width: calc(100% / 5 - 20px) !important;

      .star-full-name {
        font-size: 14px !important;
      }
      .star-movies-count {
        font-size: 10px !important;
      }
    }
  }

  @media (max-width: 800px) {
    margin-top: 6rem !important;

    .all-star-item-link {
      width: calc(100% / 3 - 15px) !important;

      .star-full-name {
        font-size: 12px !important;
      }
      .star-movies-count {
        font-size: 10px !important;
      }
    }
  }


  @media (min-width: 1367px) {
    margin-top: 5rem !important;

    .all-star-item-link {
      width: calc(100% / 9 - 20px) !important;
    }
  }

  @media (max-width: 840px) and (orientation: landscape) {
    margin-top: 6.5rem !important;
  }

  @media (max-width: 650px) {
    margin-top: 10rem !important;

    .all-star-item-link {
      width: calc(100% / 2 - 10px) !important;
    }
  }

  @media (min-width: 1921px) {
    .all-star-item-link {
      width: calc(100% / 10) !important;
    }
  }

  .all-star-item-link {
    border-radius: 8px;
    position: relative;
  }

  .star-image-container {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }

  .star-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    line-height: normal;
    padding-bottom: 5px;

    .star-full-name {
      flex: 1;
      font-size: 16px;
    }
    .star-movies-count {
      flex: 1.5;
      text-align: right;
      font-size: 13px;
    }
  }

  .effect-container {
    display: flex;
    width: 100%;
    height: 50%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 75%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
  }

}
