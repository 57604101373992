.step-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 9rem;

  &:not(:first-child) {
    &:before {
      position: absolute;
      width: 100%;
      height: 3px;
    }
  }

  .step {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
    border-radius: 9999px;
    font-weight: 600;
    color: #ffffff;
    background-color: rgb(51, 65, 85);
    cursor: pointer;
  }


  &.active {
    .step {
      background-color: rgb(2, 132, 199);
    }
  }

  &.complete {
    .step {
      background-color: rgb(22, 163, 74);
    }

    p {
      color: rgb(22, 163, 74);
    }
  }


}


.complete:not(:first-child):before,
.active:not(:first-child):before {
  background-color: rgb(22, 163, 74);
}

.stepper {

  padding: 10px 0;

  .stepper-item {



    &:last-child {
      width: auto;

      &:after {
        display: none;
      }

      .label-text {
        left: 50%;
      }

    }


    .number {
      color: rgb(51, 65, 85);
      border: 1px solid rgb(51, 65, 85);
      transition: ease-in-out 0.3s;
      background: white;
      z-index: 10;
    }

    &:after {
      position: absolute;
      content: var(--tw-content);
      width: calc(100% - 0.9rem);
      z-index: 1;
      height: 0.125rem;
      display: inline-block;
      left: 1rem;
      top: 1.25rem;
      background-color: rgb(51, 65, 85);
      transition: ease-in-out 0.3s;
    }

    .label-text {
      position: absolute;
      bottom: -15px;
      left: 10%;
      transform: translateX(-50%);
    }

    &.active {
      .number {
        color: white;
        border: 1px solid rgb(51, 65, 85);
        background-color: rgb(51, 65, 85);
      }
    }

    &.completed {
      .number {
        color: white;
        border: 1px solid rgb(22, 163, 74);
        background-color: rgb(22, 163, 74);
      }

      &:after {
        background-color: rgb(22, 163, 74);
      }

      .label {
        color: rgb(22, 163, 74);
      }
    }

  }

}