.vjs-big-play-button {
  top: 50% !important;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.vjs-modal-dialog-content {
  pointer-events: none;
}
