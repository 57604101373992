#promotions {
  width: calc(100% + 1.25rem);
  .promotion-bg {
    z-index: 5;
  }
  .promotions-section {
    z-index: 10 !important;
  }

  .promotions-content-container {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
  }

}
.dark-theme {
  .pagination-component {
    .pagination {
      z-index: 20;
      .active {
        color: rgba(250, 250, 250, 0.8);
      }
      i {
        color: $mainRed;
      }
    }
  }
}
@media (max-width: 1024px) {
  .promotions-section {
    padding-top: 70%;
  }
}
