.studios-list-container {
  grid-auto-rows: 1fr;
  .featured-studio-container {
    img {
      max-width: 160px;
    }
    .featured-item-text {
      min-height: 50px;
    }
  }

  .studio-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

    // div {
    //   position: absolute;
    //   opacity: 0;
    //   background-color: rgba(0, 0, 0, 0.5);
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   transition: all 0.3s;
    //   p {
    //     position: absolute;
    //     top: 50%;
    //     transform: translateY(-50%);
    //   }
    // }
    // &:hover {
    //   div {
    //     opacity: 1;
    //   }
    // }
  }
}

@media (min-width: 1440px) {
  .category-item-link {
    width: calc(100% / 9) !important;
  }
}
