#agreement-modal {
  z-index: 150;
  top: 50px;
  height: 130vh;
  overflow: hidden;
  box-shadow: 0 0 8px 8px white inset;
  max-width: 100%;
  // .bg-image {
  //   max-width: 100%;
  // }
  .agreement-box {
    width: 95%;
    max-width: 760px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 160;
    border-radius: 25px;
    background-color: #e1e7eb;

    button {
      max-width: 300px;
    }
  }
  font-family: $siteBaseFont;
  .agreement-close {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 160;
    display: inline-block;
    background-color: #fff;
    i {
    }
  }
  .agreement-cover-girl {
    position: absolute;
    left: -30px;
    top: -100px;
    width: 455px;
    height: auto;
    z-index: 170;
  }
  .agreement-cover-girl-tablet {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 465px;
    height: auto;
    z-index: 150;
    display: none;
  }
  .agreement-cover-girl-mobile {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 350px;
    height: auto;
    z-index: 150;
  }
}

@media (max-width: 370px) {
  .agreement-box {
    top: 10px;
  }
}

@media (min-width: 371px) and (max-width: 410px) {
  .agreement-box {
    top: 100px;
  }
}
@media (min-width: 411px) and (max-width: 450px) {
  .agreement-box {
    top: 140px;
  }
}

@media (min-width: 451px) {
  #agreement-modal {
    height: calc(100vh - 40px);
    top: 50px;
  }
  .agreement-box {
    bottom: 25px;
  }

  .agreement-cover-girl-mobile {
    display: none;
  }
  .agreement-cover-girl-tablet {
    display: block !important;
  }
}

@media (min-width: 768) {
}
@media (min-width: 1024px) {
  .agreement-box {
    top: 50%;
    transform: translateY(-53%);
    height: 600px !important;
    max-width: 1100px !important;
  }
  #agreement-modal {
    height: calc(100vh - 70px);
    top: 120px;
  }

  .agreement-cover-girl-tablet {
    display: none !important;
  }
}
@media (min-width: 1440px) {
  #agreement-modal {
    height: calc(100vh - 60px);
    top: 96px;
  }
}
