.adultWarningModalOverlay {
  z-index: 100000 !important;
}

.adultWarningContainer {
  width: 880px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 80px;
  gap: 40px;
  box-sizing: border-box;
  font-family: 'SourceSansPro';

  @apply font-normal;

  @media (max-width: 768px) and (orientation: landscape) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 769px) {
    width: 100%;
    padding: 48px 50px;
  }
  
  
  @media (max-width: 480px) {
    width: 100%;
    padding: 40px 25px;
    text-align: justify;
    min-height: calc(100vh - 25px);
    max-height: calc(100vh - 25px);
    overflow-y: auto;
    flex-shrink: 0;
  }

  @media (max-width: 380px) {
    display: block;
    justify-content: flex-start;
  }

}

.adultWarningLogo {
  display: flex;
  justify-content: center;

  @media (max-width: 480px) {
    margin-bottom: 24px;
  }

}

.adultWarningContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    margin-bottom: 24px;
  }
}

.adultWarningTitle {
  font-family: 'WorkSans';
  font-size: 32px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: center;
  margin-bottom: 16px;


  @media (max-width: 480px) {
    @apply text-2xl;
    margin-bottom: 10px;
  }

}

.adultWarningText {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
  text-align: center;
}

.adultWarningButtonsContainer {
  font-family: 'SourceSansPro';
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  @media (max-width: 480px) {
   flex-direction: column;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) and (orientation: landscape) {
    justify-content: center;
  }


}

.adultWarningButton {
  min-width: 220px;
  padding: 11px 24px;
  border-radius: 4px;
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;

  @media (max-width: 480px) {
    min-width: 100%;
    max-width: 100%;
  }
}

.buttonApprove {
  font-weight: 600;
  box-shadow: 0px 4px 10px 0px #0000001A;
}

.adultWarningSubText {
  font-size: 14px;
  text-align: center;
  line-height: 19.6px;
  font-weight: 400;
  color: #00000080;

  a {
    @apply underline text-blue-600;
  }
}

.buttonCheckIcon {
  color: black;
}

