footer {
  background-color: $mainGray1;

  .payment-icons {
    background-color: $mainGray2;

    // img {
    //   filter: grayscale(100%) contrast(0%);
    // }
  }
}
