.container {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 525px) {
    gap: 5px;
  }
}

.pill {
  display: block;
  margin-right: 8px;
  &:hover {
    opacity: 0.75;
  }
}

.pill_text {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35.5px;
  width: 100%;
  height: 100%;
  background: #e0e5ec;
  font-weight: 400;
  font-size: 15.5px;
  line-height: 14px;
  padding: 5px 15px;
}
