

.vipPlanPriceText {
  font-size: 18px;

  @media screen and (max-width: 650px) {
    font-size: 12px
  }

}

.vipPlanPriceContainer {
  height: 56px;

  @media screen and (max-width: 650px) {
    height: 45px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
}