.button {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play_icon {
  width: 26px;
  height: 26px;
  margin-right: 5px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
}
