

.promoCodeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background-color: #DBDBDB;
  border: 1px dashed #666666;
  padding: 7px 12px 9px 22px;

  @media screen and (max-width: 650px) {
    padding: 7px 9px 7px 16px;
    gap: 15px;
  }

}

.promoCodeButton {
  min-width: 130px;

  @media screen and (max-width: 650px) {
    min-width: 100px;
  }

  @media screen and (max-width: 360px) {
    min-width: 80px;
  }
}

.promoCodeInput {
    min-height: 35px !important;
    max-height: 35px;
    font-size: 12px;
    margin: 0;
}

.promoCodeInputLabel {
  font-size: 15px;

  @media screen and (max-width: 650px) {
    font-size: 13px;
  }

}

.promoCodeIcon {
  color: #848484;
  padding-bottom: 10px;
}