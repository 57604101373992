.search-results-actor-item {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  div:first-child {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  div:nth-child(2) {
    bottom: -20%;
  }
}
