@import 'src/assets/styles/partials/variables';

.noOfferFormContainer {
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 650px) {
   // box-shadow: none;
  }
}

.noOfferHeader {
  background-color: $blue-gray-bg;
  font-family: SourceSansPro;
  color:#ffffff;
  padding: 12px 80px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media screen and (max-width: 650px) {
    padding: 12px 25px 20px;
  }
}
.noOfferHeaderIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  color: $blue-gray-bg;
  padding: 20px;

  svg {
    width: 35px;
    height: 35px;
  }
}

.noOfferBodyContainer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: 650px) {
    padding: 2rem 0.5rem;
  }

}

.noOfferBodyCardsContainer {

  @media screen and (max-width: 340px) {
    gap: 0.5rem;
  }


  @media screen and (max-width: 650px) {
   // flex-direction: column;
   // align-items: center;
  }
}



.noOfferFormButton {
  min-width: 425px;
  max-width: 425px;

  @media screen and (max-width: 650px) {
    min-width: 100%;
    max-width: 100%;
  }
}

