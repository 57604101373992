.my-account {
  width: 100%;
  max-width: 1024px;
  h2 {
    font-size: 20px;
  }
  input {
    background-color: $mainGray1;
  }
}
