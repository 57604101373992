.password-show {
  position: absolute;
  top: 14px;
  right: 1rem;
  opacity: 0.6;

  &.cursor-not-allowed {
    i:hover {
      cursor: not-allowed;
    }
  }

  i:hover {
    cursor: pointer;
  }
}
