#svod-deal {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 150;
  top: 50px;
  height: 100%;
  overflow: auto;
  box-shadow: 0 0 8px 8px white inset;
  max-width: 100%;
  left: 0 !important;
  right: 0;
  padding-bottom: 100px !important;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  // .bg-image {
  //   max-width: 100%;
  // }
  .text_logo_container {
    img {
      max-width: 120px;
      height: 15px;
    }
  }
  .svod_deal_info_container {
    background-color: #f4f7fb;
    border-radius: 8px;
    overflow: auto;
    max-width: 95%;
    .list-dot {
      font-size: 6px;
    }
    .svod_deal-info-header-text {
      font-size: 10px;
    }
    .svod_deal-info-list-text {
      font-size: 10px;
    }
  }
  .vip_square_logo {
    i {
      font-size: 6px;
    }
    span {
      font-size: 8px;
    }
  }
  .vip_round_logo {
    border-radius: 50%;
    i {
      font-size: 6px;
      padding-top: 2px;
    }
    span {
      font-size: 8px;
    }
  }
  .club-deal-modal-box {
    width: 90%;
    max-width: 760px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 160;
    height: auto !important;
    margin-bottom: 100px;
    padding-bottom: 100px;
    button {
      max-width: 300px;
    }
    .club-deal-modal-box-content {
      background-color: #e0e5ec;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
  font-family: $siteBaseFont;
  .agreement-close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 160;
    display: inline-block;
  }
  .agreement-cover-girl {
    position: absolute;
    left: -30px;
    top: -100px;
    width: 455px;
    height: auto;
    z-index: 170;
  }
  .agreement-cover-girl-tablet {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 465px;
    height: auto;
    z-index: 150;
    display: none;
  }
  .agreement-cover-girl-mobile {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 350px;
    height: auto;
    z-index: 150;
  }
  .club_deal_price_month {
    background-color: #767c88;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .club_deal_price_amount {
    border-radius: 5px;
    z-index: 50 !important;
  }
  .club_deal_price_button {
    margin: 0;
    border-radius: 5px;
  }
  .club_deal_left_girl_desktop {
    top: 70px;
    left: -150px;
  }
  .club_deal_right_girl_desktop {
    top: 65px;
    right: -160px;
  }

  .club_deal_left_girl_mobile {
    width: 110px;
    top: 78px;
    left: -18px;
  }
  .club_deal_right_girl_mobile {
    width: 110px;
    top: 78px;
    right: -18px;
  }
}

@media (max-width: 370px) {
  #svod-deal {
    .club-deal-modal-box {
      top: 10px;
    }
    .club_deal_left_girl_mobile {
      width: 120px;
      top: 78px;
      left: -18px;
    }
    .club_deal_right_girl_mobile {
      width: 120px;
      top: 78px;
      right: -18px;
    }
  }
}

@media (min-width: 371px) and (max-width: 410px) {
  #svod-deal {
    .club-deal-modal-box {
      top: 30px;
    }
    .club_deal_left_girl_mobile {
      width: 130px;
      top: 60px;
      left: -18px;
    }
    .club_deal_right_girl_mobile {
      width: 130px;
      top: 60px;
      right: -18px;
    }
  }
}
@media (min-width: 411px) and (max-width: 450px) {
  #svod-deal {
    .club-deal-modal-box {
      top: 30px;
    }
    .club_deal_left_girl_mobile {
      width: 140px;
      top: 60px;
      left: -20px;
    }
    .club_deal_right_girl_mobile {
      width: 140px;
      top: 60px;
      right: -20px;
    }
    .svod_deal-info-header-text {
      font-size: 13px !important;
    }
    .svod_deal-info-list-text {
      font-size: 10px !important;
    }
    .list-dot {
      font-size: 6px !important;
    }
  }
}

@media (min-width: 451px) {
  #svod-deal {
    height: calc(100vh - 40px);
    top: 50px;

    .agreement-cover-girl-mobile {
      display: none;
    }
    .agreement-cover-girl-tablet {
      display: block !important;
    }

    .club_deal_left_girl_mobile {
      width: 150px;
      top: 70px;
      left: -22px;
    }
    .club_deal_right_girl_mobile {
      width: 150px;
      top: 70px;
      right: -22px;
    }
    .svod_deal-info-header-text {
      font-size: 15px !important;
    }
    .svod_deal-info-list-text {
      font-size: 12px !important;
    }
    .list-dot {
      font-size: 6px !important;
    }
  }
}

@media (min-width: 555px) {
  #svod-deal {
    .club-deal-modal-box {
      top: 40px;
    }
    .svod_deal-info-header-text {
      font-size: 18px !important;
    }
    .svod_deal-info-list-text {
      font-size: 14px !important;
    }
    .list-dot {
      font-size: 8px !important;
    }

    .club_deal_left_girl_mobile {
      width: 190px;
      top: 60px;
      left: -28px;
    }
    .club_deal_right_girl_mobile {
      width: 190px;
      top: 60px;
      right: -28px;
    }
  }
}

@media (min-width: 767px) {
  #svod-deal {
    .club-deal-modal-box {
      top: 40px;
      max-width: 700px !important;
    }
    .svod_deal_info_container {
      border-radius: 30px;
    }
    .text_logo_container {
      img {
        max-width: 220px;
        height: auto;
      }
    }
    .vip_square_logo {
      i {
        font-size: 10px;
        padding-top: 2px;
      }
      span {
        font-size: 18px;
      }
    }
    .vip_round_logo {
      border-radius: 50%;
      i {
        font-size: 6px;
        padding-top: 2px;
      }
      span {
        font-size: 12px;
      }
    }
    .svod_deal-info-header-text {
      font-size: 20px !important;
    }
    .svod_deal-info-list-text {
      font-size: 18px !important;
    }
    .list-dot {
      font-size: 8px !important;
    }
    .club_deal_left_girl_mobile {
      top: 70px !important;
      width: 230px;
      left: -37px;
    }
    .club_deal_right_girl_mobile {
      top: 70px !important;
      width: 230px;
      right: -37px;
    }
    .club_deal_left_girl_tablet {
      top: 80px !important;
      width: 230px;
      left: -37px;
    }
    .club_deal_right_girl_tablet {
      top: 80px !important;
      width: 230px;
      right: -37px;
    }
    .club_deal_price_month {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .club_deal_price_amount {
      border-radius: 16px;
    }
    .club_deal_price_button {
      border-radius: 16px;
    }
  }
}

@media (min-width: 819px) {
  .club_deal_left_girl_tablet {
    top: 80px !important;
    width: 230px;
    left: -53px !important;
  }
  .club_deal_right_girl_tablet {
    top: 80px !important;
    width: 230px;
    right: -53px !important;
  }
}

@media (min-width: 1024px) {
  #svod-deal {
    height: calc(100vh - 70px);
    top: 120px;
    .agreement-cover-girl-tablet {
      display: none !important;
    }
    .club-deal-modal-box {
      top: 100px;
      max-width: 700px !important;
    }

    .club_deal_left_girl_desktop {
      width: 350px;
    }
    .club_deal_right_girl_desktop {
      width: 350px;
    }
  }
}

@media (min-width: 1200px) {
  #svod-deal {
    height: 100% !important;
    top: 96px;
    padding-bottom: 200px !important;
    .club-deal-modal-box {
      top: 70px;
      max-width: 800px !important;
      height: 150vh !important;
    }
    .club_deal_left_girl_desktop {
      top: 70px;
      left: -140px;
      width: 410px;
    }
    .club_deal_right_girl_desktop {
      top: 70px;
      right: -200px;
      width: 410px;
    }
  }
}
