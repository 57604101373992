.home-top-banners {
  flex: 1 0 auto;
  height: fit-content;
  .movie-poster-item {
    height: 100%;
  }

  .promo-slider {
    align-self: stretch;
  }

  .top-banners-small-container {
    align-self: stretch;
    display: none;

    img {
      object-fit: contain !important;
    }
  }
}

.home-page {

  .item-skeleton {
    min-height: 350px;
    aspect-ratio: 360 / 517 !important;
  }

  .beate-swiper {
    &.scenes {
      @media (min-width: 1600px) {
        min-height: 260px;
      }

      @media (min-width: 1366px) and (max-width: 1599px) {
        min-height: 180px;
      }

      @media (min-width: 1024px) and (max-width: 1365px) {
        min-height: 130px;
      }


      @media (max-width: 650px) {
        min-height: 365px;
      }

      @media (max-width: 320px) {
        min-height: 290px;
      }
    }

    @media (min-width: 1600px) {
      min-height: 360px;
    }

    @media (min-width: 1366px) and (max-width: 1599px) {
      min-height: 245px;
    }

    @media (min-width: 1024px) and (max-width: 1365px) {
      min-height: 170px;
    }

    @media (max-width: 650px) {
      min-height: 285px;
    }

    @media (max-width: 320px) {
      min-height: 205px;
    }

  }

  .recomended-slider {

    .swiper {
      padding: 10px;

      &.scenes {
        @media (max-width: 650px) {
          padding: 0;
        }
      }

    }

    .swiper-slide {
      .play-icon-container {
        left: 0;
      }
    }

    .disabledPrev {
      .swiper-button-prev {
        opacity: 0 !important;
      }
    }



    @media only screen and (max-width: 767px) {
      .poster-list-item-recommended {
        .poster-icon {
          img {
            opacity: 1 !important;
          }
        }
      }
    }


    &.custom {
      .poster-list-item-recommended {
        .poster-icon {
          img {
            aspect-ratio: 360 / 517 !important;
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;
          }
        }
      }
    }

    &.amateurs {
      .poster-list-item-recommended {

        .poster-icon {
          img {
            aspect-ratio: 360 / 517 !important;
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;

          }
        }
      }
    }

    &.latest {
      .poster-list-item-recommended {
        .carousel-scene {
          aspect-ratio: 4 / 3 !important;
          max-width: 100% !important;
          min-width: 100% !important;
          width: 100% !important;

          .lazy-load-image-background {
            width: 100%;
            height: 100%;
          }

          .poster-icon {
            img {
              aspect-ratio: 4 / 3 !important;
              min-height: 100% !important;
              max-width: 100% !important;
              min-width: 100% !important;
              width: 100% !important;
            }
          }

        }

        .poster-icon {
          img {
            aspect-ratio: 360 / 517 !important;
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;
          }
        }
      }
    }




    &.top100 {
      .poster-list-item-recommended {
        .carousel-scene {
          aspect-ratio: 4 / 3 !important;
          max-width: 100% !important;
          min-width: 100% !important;
          width: 100% !important;

          .lazy-load-image-background {
            width: 100%;
            height: 100%;
          }

          .poster-icon {
            img {
              aspect-ratio: 4 / 3 !important;
              max-width: 100% !important;
              min-width: 100% !important;
              width: 100% !important;
            }
          }

        }

        .poster-icon {
          img {
            aspect-ratio: 360 / 517 !important;
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;
          }
        }
      }
    }

    &.new {
      .poster-list-item-recommended {
        .poster-icon {
          img {
            aspect-ratio: 360 / 517 !important;
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;
          }
        }
      }
    }

  }

  .poster-list-item-recommended {
    //border: 4px solid transparent;
    img {
      object-fit: fill !important;
    }
  }
}


@media (max-width: 650px) {
  .home-page {
    .poster-list-item-recommended {

      @media only screen and (max-width: 767px) {
        .poster-icon {
          opacity: 1;
        }
        .play-icon-container {
          opacity: 0 !important;
        }
      }

      .carousel-scene {
        @media only screen and (max-width: 767px) {
          .vjs-poster {
            aspect-ratio: 4/3;
          }
        }
       /* @media only screen and (max-width: 450px) {
          max-height: 298px;
        }*/


        .poster-icon {
          object-fit: fill !important;
          min-height: 293px;
        }
      }
    }
  }
}


.poster-list-container {
  grid-auto-rows: 1fr;
}

@media (min-width: 768px) {
  .special-offers-container {
    margin-top: 0;
    margin-bottom: 0;
  }
  .top-banners-small-container {
    display: flex !important;
  }
}

@media (min-width: 1280px) {
  .promo-slider {
    width: 100% !important;
  }


  .top-banners-small-container {
    margin-bottom: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }

  .top-banners-small-container-less-than-three {
    justify-content: start !important;
    div {
      margin-bottom: 20px !important;
    }
  }
}
