@import 'src/assets/styles/partials/variables';

.successPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  color: #000000;
  line-height: 125%;
  font-family: SourceSansPro;
  width: 100%;
  height: 100%;
}

.iconContainer {
  padding: 40px;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  svg {
    width: 45px;
    height: 45px;
  }
}

.yellow {
  color: $yellow-bg;
  border-color:  $yellow-bg;
}

.purple {
  color: $purple-bg;
  border-color: $purple-bg
}

.logoContainer {
  padding: 45px 15px;
  border-radius: 100%;
  position: relative;
  background-color: white;


  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: -1;
    border-radius: 50%;
    border: 1px solid transparent; /* Make sure the border is set to transparent */
    background: linear-gradient(90deg, black 0%, #dd0000 47%, #ffc200 100%);
  }

  img {
      max-width: 100%;
      max-height: 25px;
  }
}

.successTitle {
  font-size: 31px;
  font-weight: bold;
  text-align: center;
  line-height: 120%;

  @media screen and (max-width: 650px) {
    font-size: 26px;
  }
}

.successText {
  font-size: 18px;
  line-height: 154%;
  font-weight: normal;
  text-align: center;

  @media screen and (max-width: 650px) {
    font-size: 16px;
  }

}


.successTextPromo {
  max-width: 55%;
  text-align: center;

  @media screen and (max-width: 650px) {
    max-width: 100%;
  }
}

.successButton {
  max-width: 415px;
}
