.seoFooter_wrapper {
  margin: 5%;
}

.seoFooter_item {
  position: relative;
  color: #444;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  outline: none;
  border-bottom: 1px solid black;
}

.seoFooter_item .button {
  width: 100%;
}

.seoFooter_item .button:focus {
  outline: none;
}

.title {
  /* float: left; */
}

.seoFooter_item_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.3s;
}
.seoFooter_item_wrapper.open {
  height: auto;
}
