.price_container {
  width: 100%;
  max-width: 227px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-content: center;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  &:focus {
    outline: 0;
  }
  border-radius: 5px;
  background: rgba(255, 255, 255, 1);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 0.5rem;
}

.price_title {
  font-weight: 400;
  font-size: 14.5px;
  line-height: 16.5px;
}

.amount_container,
.price_title {
  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
