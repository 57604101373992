
.personalInformationContainer {
    width: 435px;

    @media screen and (max-width: 650px) {
        width: 100%;
        padding-bottom: 0;
    }
}

.personalInformationPromoCodeContainer {
    margin-top: 5px;
}


.personalInformationBottomText {
    font-size: 10px;
    opacity: 0.5;
    text-align: center;

    @media screen and (max-width: 400px) {
        margin-top: 10px;
    }
}

.personalInformationLoginPart {
    font-size: 1.25rem;

    @media screen and (max-width: 400px) {
        font-size: 1rem;
    }
}