.container {
  width: 227px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  align-content: center;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  &:focus {
    outline: 0;
  }
  border-radius: 5px;
  background: rgba(255, 255, 255, 1);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 0.5rem;
}
