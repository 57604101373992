.movie_page_poster {
  width: 100%;
  max-width: 313px;
  position: relative;
}

.border_radius {
  border-radius: 8px;
}

.wishlist {
  position: absolute;
  bottom: 10px;
  left: -10px;
}
