@import 'src/assets/styles/partials/variables';

.promoFormContainer {
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 650px) {
    box-shadow: none;
  }
}

.promoHeader {
  background-color: $light-blue-gray-bg;
  font-family: SourceSansPro;
  color: #000000;
  padding: 12px 80px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media screen and (max-width: 650px) {
    padding: 12px 25px 20px;
  }
}

.promoHeaderIcon {
  background-color: $blue-gray-bg;
  border-radius: 100%;
  color: #ffffff;
  padding: 20px;

  svg {
    width: 35px;
    height: 35px;
  }

  @media screen and (max-width: 650px) {
    padding: 15px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

}


.promoHeaderTitle {
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 650px) {
    font-size: 16px;
  }
}

.promoHeaderText {
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  @media screen and (max-width: 650px) {
    font-size: 14px;
  }
}

.promoHeaderAreaText {
  margin-top: 10px;
  padding: 14px 40px;
  background-color: $blue-gray-bg;
  color: #ffffff;
  text-align: center;
  font: normal normal 16px/125% "SourceSansPro";

  @media screen and (max-width: 650px) {
    font-size: 14px;
  }

}


.promoBodyContainer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: 650px) {
    padding: 1.25rem 1rem 1.25rem !important;
  }
}

.promoPackagesMethodContainer {
  gap: 45px;
  height: 365px;

  @media screen and (max-width: 650px) {
    gap: 10px;
  }
}

.promoButtonContainer {

  @media screen and (max-width: 650px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

