body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.home-page {

   .hpc-row {

    &:nth-child(even){
      .hpc-background {
        display: block !important;
        background-color: #F0F0F0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
}

body .react-responsive-modal-overlay {
  padding-top: 0 !important;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .border {
    border-width: 1px;
  }
  .rounded {
    border-radius: 0.25rem;
  }
}